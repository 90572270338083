<template>
  <b-container class="listing mx-auto">
    <div class="mx-0 bg-white listing-easy">
      <div class="listing-easy-text">
        <h1 class="h5 m-0">{{$t('ListingProcess.title')}}</h1>
      </div>
      <div class="listing-easy-option">
        <div
          v-if="listingPage > 2 && $store.getters.listingPlanID === 2"
          class="text-center text-lg-right"
        >
          <span>{{$t('ListingProcess.currentListingOption')}}</span>
          <span class="d-inline-block ml-3 py-2 px-4 border rounded">
            <!--bg-recommend-light rounded': $store.getters.listingPlanID === 2-->
            <span class="h5 m-0"> {{ $store.getters.listingPlanName }}</span>
          </span>
        </div>
        <div v-else class="text-center text-lg-right">
          <span>{{$t('ListingProcess.currentListingOption')}}</span>

          <b-button
            v-b-toggle.planSelector
            id="planSelecterbtn"
            class="bg-transparent border-0"
          >
            <span>
              <!--bg-recommend-light rounded': $store.getters.listingPlanID === 2-->
              <span class="m-0 select-option-text">
                {{ $store.getters.listingPlanName }}
              </span>
              <b-icon
                icon="caret-down-fill"
                variant="primary"
                scale="0.5"
                class="ml-1"
              />
            </span>
          </b-button>
        </div>
        <!-- <p>listingPage: {{ listingPage }}</p> -->
      </div>
    </div>

    <b-collapse id="planSelector">
        <TheListingPlanSelecter />
    </b-collapse>

    <section v-if="lookup">
      <div class="vin-lookup">
        <h2>{{ $t("ListingProcess.VINLookup") }}</h2>
        <p class="mt-3 mb-5">
          {{ $t("ListingProcess.VINMessage1") }}
          <b-link v-b-modal.findVIN style="text-decoration: underline"
            >{{ $t("ListingProcess.VINMessage2") }}</b-link>
          {{ $t("ListingProcess.VINMessage3") }}

        </p>

        <b-input-group class="m-auto vin-search">
          <b-form-input
            v-model="vin"
            type="text"
            :disabled="overlay"
            :state="availableVIN"
            @input="checkVIN()"
            @keydown.space.prevent
            class="left-rounded text-uppercase vin-input"
            :placeholder="$t('ListingProcess.enterVinPlaceholder')"
            :class="{ 'border-danger': errorMessage }"
          />
          <b-input-group-append>
            <b-overlay :show="overlay">
              <!-- :disabled="!availableVIN" -->
              <b-btn class="right-rounded" variant="primary" @click="vinLookup()" :disabled="errorMessage">
                {{ $t("ListingProcess.Search") }}
              </b-btn>
            </b-overlay>
          </b-input-group-append>
        </b-input-group>
        <div v-if="errorMessage" class="mt-3 mx-auto w-75 alert alert-danger alert-icon">
          <span>{{ errorMessage }}</span>
        </div>

        <b-modal id="findVIN" size="lg" hide-footer centered>
          <template #modal-title> {{ $t("ListingProcess.VehicleIdentification") }} </template>

          <div class="p-4">
            <h3 class="h6">{{ $t("ListingProcess.VehicleIdentification") }}</h3>
            <p>
              {{ $t("ListingProcess.VINMessage4") }}
            </p>

            <h3 class="h6 mt-4">{{ $t("carDetail.label.VINLookupQuestions") }}</h3>
            <p>
              {{ $t("carDetail.label.vinExactly17") }}
            </p>

            <div class="row mb-5">
              <div class="col-12 text-center">
                <img
                  class="w-100 img-fluid"
                  src="@/assets/img/photoGuideSVG/ExampleVIN.png"
                />
              </div>
            </div>

            <div class="row">
              <div class="col text-center">
                <img class="w-100 img-fluid" src="@/assets/img/photoGuideSVG/VIN.png" />
              </div>
              <div class="col text-center">
                <img
                  class="w-100 img-fluid"
                  src="@/assets/img/photoGuideSVG/VINnumber.png"
                />
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <b-button pill class="cancel-listing" :to="{ name: 'listingOption' }">
        <v-icon>mdi-close</v-icon> {{ $t("global.button.cancel") }}
      </b-button>
    </section>

    <section v-else-if="mileageReader" class="vin-details">
      <div class="border-bottom">
        <h2>{{ $store.getters.vehicleName }}</h2>
        <p class="mini-font">{{ $t("ListingProcess.VIN") }}: {{ vin }}</p>
        <b-link @click="lookup = true" class="change-vehicle">{{ $t("ListingProcess.ChangeVehicle") }}</b-link>
      </div>

      <p class="odometr-reading">{{ $t("ListingProcess.OdometerReading") }}</p>
      <b-input-group class="mx-auto odometr-box">
        <b-form-input
          type="number"
          min="0"
          class="left-rounded vin-input"
          v-model="mileage"
          oninput="this.value = Math.abs(this.value)"
          @input="$store.dispatch('vehicleMileage', mileage)"
          :placeholder='$t("ListingProcess.EnterMileage")'
        />
        <b-input-group-append>
          <b-dropdown
            :text="odometer"
            class="right-rounded border"
            style="max-height: 38px"
            variant="transparent"
          >
            <b-dropdown-item @click="kmReading()">{{ $t("ListingProcess.Kilometers") }}</b-dropdown-item>
            <b-dropdown-item @click="miReading()">{{ $t("ListingProcess.Miles") }}</b-dropdown-item>
          </b-dropdown>
        </b-input-group-append>
      </b-input-group>

      <div class="my-4">
        <b-button @click="mileageReader = false" class="getstarted-btn" variant="primary"
          >{{ $t("ListingProcess.GetStarted") }}</b-button
        >
      </div>
    </section>

    <section v-else>
      <vue-good-wizard
        ref="listingWizard"
        :steps="steps"
        :onNext="nextClicked"
        :onBack="backClicked"
        :nextStepLabel="$t('listing.label.next')"
        :previousStepLabel="$t('listing.label.back')"
        :finalStepLabel="$t('listing.label.finish')"
        class="listing-wizard"
      >
        <div slot="page1">
          <TheBasicDetails
            :restart="restart"
            :nextButton="nextButtonDisabled"
            :styleList="styleList"
          />
        </div>
        <!-- <div slot="page2">
            <TheListingHistory
              :nextButton="nextButtonDisabled"
              :toPage="goToPage"
            />
          </div> -->
        <div slot="page3">
          <TheListingPhoto :nextButton="nextButtonDisabled" :toPage="goToPage" />
        </div>
        <!-- <div slot="page4"><TheListingCondition :toPage="goToPage" /></div> -->
        <div slot="page4">
          <TheListingPrice :nextButton="nextButtonDisabled" />
        </div>
        <div slot="page5">
          <TheListingLocation :nextButton="nextButtonDisabled" />
        </div>
        <div slot="page6">
          <TheListingReview ref="reviewPage" :toPage="goToPage" :nextButton="nextButtonDisabled" />
        </div>
        <div slot="page7"><TheListingCheckout /></div>
      </vue-good-wizard>
    </section>

    <b-modal
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      id="notYourVehicle"
    >
      <div class="p-4">
        <h4>
          <b-icon
            icon="exclamation-triangle-fill"
            scale="1.5"
            variant="recommend"
            class="mr-3"
          ></b-icon
          ><b>{{ $t("ListingProcess.notMyVehicle") }}</b>
        </h4>
        <div class="p-4">
          <b>
            {{ $t("ListingProcess.resetDataWarn") }}
          </b>
        </div>
        <b-row class="text-center">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              @click="restart()"
            >
              {{ $t("ListingProcess.StartOver") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('notYourVehicle')"
            >
              {{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      id="cancelModal"
    >
      <div class="p-4">
        <h4>
          <b-icon
            icon="exclamation-triangle-fill"
            scale="1.5"
            variant="recommend"
            class="mr-3"
          ></b-icon
          >{{ $t("ListingProcess.AreYouSure") }}
        </h4>
        <div class="p-3">
          <b>{{ $t("ListingProcess.cancelModalWarn") }}</b>
        </div>
        <b-row class="text-center">
          <b-col>
            <b-btn
              variant="recommend"
              pill
              size="sm"
              style="width: 100px"
              :to="{ name: 'listingOption' }"
              @click="cleanListingData(), removeListing()"
            >
              {{ $t("global.button.cancel") }}
            </b-btn>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              pill
              size="sm"
              style="width: 100px"
              @click="$bvModal.hide('cancelModal')"
            >
              {{ $t("ListingProcess.agreementModalContinue") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";

export default {
  components: {
    TheListingPlanSelecter: () => import("@/components/listing/TheListingPlanSelecter"),
    TheBasicDetails: () => import("@/components/listing/TheListingBasicDetails"),
    // TheListingHistory: () => import("@/components/listing/TheListingHistory"),
    TheListingPhoto: () => import("@/components/listing/TheListingPhoto"),
    // TheListingCondition: () =>
    //   import("@/components/listing/TheListingCondition"),
    TheListingPrice: () => import("@/components/listing/TheListingPrice"),
    TheListingLocation: () => import("@/components/listing/TheListingLocation"),
    TheListingReview: () => import("@/components/listing/TheListingReview"),
    TheListingCheckout: () => import("@/components/listing/TheListingCheckout"),
  },
  data() {
    return {
      listingPage: 0,
      tagValueParams: [],
      lookup: true,
      mileageReader: false,
      vin: "",
      availableVIN: null,
      overlay: false,
      errorMessage: null,
      vinLookupError: false,
      styleList: null,
      mileage: null,
      odometer: "km",
    };
  },
  computed: {
    steps() {
      if (this.$store.getters.listingPlanID === 1) {
        return [
          {
            label: this.$t("Sell.steps.BasicDetails"),
            slot: "page1",
            options: {
              nextDisabled: true
            }
          },
          // {
          //   label: "History",
          //   slot: "page2",
          //   options: {
          //     nextDisabled: true
          //   }
          // },
          {
            label: this.$t("Sell.steps.VehiclePhotos"),
            slot: "page3",
            options: {
              nextDisabled: true
            }
          },
          {
            label: this.$t("Sell.steps.Price"),
            slot: "page4",
            options: {
              nextDisabled: true
            },
          },
          {
            label: this.$t("Sell.steps.Location"),
            slot: "page5",
            options: {
              nextDisabled: true
            },
          },
          {
            label: this.$t("Sell.steps.Review"),
            slot: "page6",
            options: {
              nextDisabled: true
            },
          },
        ];
      } else {
        return [
          {
            label: this.$t("Sell.steps.BasicDetails"),
            slot: "page1",
            options: {
              nextDisabled: true,
            },
          },
          {
            label: this.$t("Sell.steps.VehiclePhotos"),
            slot: "page3",
            options: {
              nextDisabled: true,
            },
          },
          {
            label: this.$t("Sell.steps.Price"),
            slot: "page4",
            options: {
              nextDisabled: true,
            },
          },
          {
            label: this.$t("Sell.steps.Location"),
            slot: "page5",
            options: {
              nextDisabled: true,
            },
          },
          {
            label: this.$t("Sell.steps.Review"),
            slot: "page6",
            options: {
              nextDisabled: true,
            },
          },
          {
            label: this.$t("Sell.steps.Checkout"),
            slot: "page7",
            options: {
              nextDisabled: true,
            },
          },
        ];
      }
    },
    vinState() {
      if (this.errorMessage) {
        return false;
      } else if (!this.vin) {
        return null;
      } else return this.vin.length === 17;
    },
  },
  methods: {
    scrollToTop(){
      const wizardElement = document.querySelector('.listing-wizard');
      if(wizardElement) wizardElement.scrollIntoView();
    },
    nextClicked(currentPage) {
      this.listingPage = currentPage + 1;
      if(currentPage=== 4 && this.$store.getters.listingPlanID !== 2) {
        this.$refs.reviewPage.ListingDone();
        this.$router.replace({ name: 'ListingSuccess' })
        return false
      }
      if (this.steps[currentPage].options.nextDisabled) return false;
      this.scrollToTop();
      return true; //return false if you want to prevent moving to next page
    },
    backClicked(currentPage) {
      this.listingPage = currentPage - 1;
      this.scrollToTop();
      return true; //return false if you want to prevent moving to previous page
    },
    nextButtonDisabled(page, boolean) {
      try {
        this.steps.forEach((element) => {
          if (element.slot === page) {
            element.options.nextDisabled = boolean;
          }
        });
      }catch (e){
        return true
      }
    },
    goToPage(page) {
      this.$refs["listingWizard"].goTo(page);
    },
    restart() {
      this.lookup = true;
      Object.assign(this.$data, this.$options.data());
      this.cleanListingData();
      this.removeListing();
      this.$bvModal.hide("notYourVehicle");
    },
    async removeListing() {
      try {
        await axios.post("Listing/RemoveListing", {
          ListingId: this.$store.getters.listingStockID,
        });
        this.$store.dispatch("listingStockID", null);
      } catch (error) {
        console.log(error);
      }
    },
    async checkVIN() {
      this.errorMessage = null;
      if (this.vin && this.vin.length !== 17) {
        this.errorMessage = this.$t("carDetail.label.vinLengthError");
      }
      this.availableVIN = null;
      let vin = this.vin.replace(/\s/g, "");
      if (this.vinState) {
        try {
          const response = await axios.post("Listing/IsVINValid", {
            VIN: vin,
          });
          this.availableVIN = response.data;
        } catch (error) {
          this.errorMessage = error.response.data;
        }
      }
    },
    async vinLookup() {
      this.errorMessage = null;
      if (this.vinState) {
        try {
          this.overlay = true;
          let VIN = this.vin.replace(/\s/g, "");
          const response = await axios.post("Listing/VehicleStyle", {
            VIN: VIN,
            OriginCountryCode: this.$store.getters.country.Code,
          });
          this.styleList = response.data;
          if (response.data.ManualListing) {
            this.errorMessage =
              "Auto listing is not available for this VIN, Manual Listing feature coming soon";
            this.overlay = false;
          } else if (response.data) {
            const l = response.data[0];
            let modelYear, makeName, modelName, noOfPassengerDoors, noOfSeats;
            this.$store.dispatch("vin", this.vin);
            this.$store.dispatch("listingStockID", null);
            l.ListingTags.forEach((element) => {
              if (element.TagId === 1) modelYear = element.Value;
              if (element.TagId === 2) makeName = element.Value;
              if (element.TagId === 3) modelName = element.Value;
              if (element.TagId === 6) noOfPassengerDoors = element.Value;
              if (element.TagId === 7) noOfSeats = element.Value;
            });
            this.$store.dispatch(
              "vehicleName",
              modelYear + " " + makeName + " " + modelName
            );

            if (noOfPassengerDoors) {
              this.$store.dispatch("vehicleDoors", noOfPassengerDoors);
              this.$store.dispatch("hasDoorInfo", true);
            } else if (!noOfPassengerDoors) this.$store.dispatch("hasDoorInfo", false);
            if (noOfSeats) {
              this.$store.dispatch("vehicleSeats", noOfSeats);
              this.$store.dispatch("hasSeatInfo", true);
            } else if (!noOfSeats) this.$store.dispatch("hasSeatInfo", false);
            this.lookup = false;
            this.mileageReader = true;
            this.overlay = false;
            this.errorMessage = null;
            // this.setListener();
          } else {
            this.overlay = false;
            this.errorMessage = "no data received";
          }
        } catch (error) {
          console.log(error);
          this.errorMessage = error;
          this.overlay = false;
        }
      } else {
        if (!this.availableVIN) {
          this.errorMessage = this.$t("carDetail.label.enterVinError");
        }
        this.overlay = false;
      }
    },
    cleanListingData() {
      const storeName = [
          "vehicleStyle",
          "vehicleStyleId",
          "vin",
          "vehicleName",
          "vehicleTrim",
          "vehicleEngine",
          "vehicleDrivetrain",
          "vehicleTransmission",
          "vehicleFuelType",
          "vehicleBodyType",
          "vehicleEngineDisplacement",
          "vehicleDoors",
          "vehicleSeats",
          "vehicleMileage",
          "vehicleNumberOfKeys",
          "vehicleColor",
          "vehicleColorInterior",
          "vehiclePrice",
          "vehicleMileage",
          "questions",
          "countSelects",
          "vehicleUsage",
          "vehiclePrimaryImage",
          "vehicleLocation",
          "vehicleLocation2",
          "vehicleCity",
          "vehicleProvince",
          "vehiclePostal",
          "vehicleContactFirstName",
          "vehicleContactLastName",
          "vehicleContactMobile",
          "vehicleContactMobileCountryCode",
          "vehicleContactEmail",
        ],
        storeFalse = [
          "hasDoorInfo",
          "hasSeatInfo",
          "vehicleImagesUploaded",
          "vehicleSameContact",
          "vehicleSameAddress",
        ],
        storeArray = ["vehicleImageList", "detailPageShowMore"];
      storeName.forEach((name) => {
        this.$store.dispatch(name, null);
      });
      storeFalse.forEach((name) => {
        this.$store.dispatch(name, false);
      });
      storeArray.forEach((name) => {
        this.$store.dispatch(name, []);
      });
    },
    beforeunloadHandler() {
      // if (e || window.event) {
      //   return this.$bvModal.show('cancelModal')
      // }
      // else return null;
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (e) {
          e.returnValue = "Do you want reload this page, all process will be lost";
        }
        return "All process will be lost";
      };
    },
    back() {
      if (this.listingPage > 0) {
        this.listingPage -= 1;
        this.$refs["listingWizard"].goTo(this.listingPage);
      } else if (this.listingPage === 0 && !this.lookup) {
        this.$bvModal.show("cancelModal");
      }
    },
    setListener() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.back, false);
      // window.addEventListener("beforeunload", e => {
      //   this.beforeunloadHandler(e);
      // }, false);
      this.beforeunloadHandler();
    },
    removeListener() {
      window.removeEventListener("popstate", this.back, false);
      // window.removeEventListener("beforeunload", e => {
      //   this.beforeunloadHandler(e);
      // }, false);
      window.onbeforeunload = null;
    },
    kmReading() {
      this.odometer = "km";
      this.$store.dispatch("mileageUnit", this.odometer);
    },
    miReading() {
      this.odometer = "mi";
      this.$store.dispatch("mileageUnit", this.odometer);
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.getters.user) {
        vm.$bvModal.show("signin");
        vm.$router.back();
      }
    });
  },
  // beforeMount() {
  //   this.setListener();
  // },
  beforeDestroy() {
    this.cleanListingData();
    this.removeListener();
  },
  updated() {
    if (this.lookup) {
      this.cleanListingData();
    }
    // this.$refs.planSelecter.$emit("close");
  },
  beforeMount() {
    this.$store.dispatch("setCurrency", "CAD");
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/custom.scss";
.wizard__body {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.final-step{
  position: relative;
  z-index: 10;
}
.wizard__arrow
  /* ,
  .wizard__body,
  .wizard__body__actions  */ {
  border: 0 !important;
  background-color: transparent !important;
}
.wizard__body__actions a {
  text-decoration: none !important;
}

.bar {
  position: relative;
  bottom: 21px;
  float: right;
  width: 178px;
  margin-right: 234px;
  border-style: solid;
  border-width: 2px;
  border-color: $half-dark;
  background: $half-dark;
}

.popover {
  max-width: 1000px !important;
}

@media (max-width: 1200px) {
  .bar {
    width: 118px;
    margin-right: 204px;
  }
}

@media (max-width: 991px) {
  .bar {
    width: 38px;
    margin-right: 164px;
  }
  .popover {
    max-width: 500px !important;
  }
}
.listing {
  margin: 64px 0;
  @media (max-width: 767px) {
    margin: 32px 0;
  }
  .vehicle-details{
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .listing-wizard {
    counter-reset: li;
    .wizard__body {
      background: none !important;
      &__actions {
        a {
          i {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            -moz-osx-font-smoothing: grayscale;
            &.vgw-next {
              margin-left: 8px;
              &:before {
                content: "\f061";
              }
            }
            &.vgw-prev {
              margin-right: 8px;
              &:before {
                content: "\f060";
              }
            }
            &:after {
              display: none !important;
            }
          }
        }
      }
    }
    .wizard__steps {
      margin: 64px 0;
    }
    .wizard__step__indicator {
      width: 40px !important;
      height: 40px !important;
      box-sizing: border-box;
      border: 1px solid white !important;
      background: #dbdbdb !important;
    }
    .wizard__step {
      position: relative;
      &:after {
        content: counter(li);
        counter-increment: li;
        position: absolute;
        top: -28px;
        left: 50%;
        z-index: 2;
        transform: translateX(5px);
        font-size: 14px;
        color: #363636;
        font-family: "Inter", sans-serif !important;
      }
      &__label {
        font-weight: 400;
        font-size: 14px;
        font-family: "Inter", sans-serif;
        position: relative;
        top: 12px;
        color: #363636;
      }
      &.active {
        .wizard__step__indicator {
          border: 13px solid #006ac3 !important;
          background: white !important;
        }
        &:after {
          opacity: 0;
        }
        .wizard__step__indicator {
          // border: none !important;
          // background: #006ac3 !important ;
          // &:after {
          //   content: "\f00c";
          //   position: absolute;
          //   width: 100%;
          //   height: 100%;
          //   top: 0;
          //   right: 0;
          //   font-family: "FontAwesome";
          //   color: white;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   font-size: 23px;
          // }
        }
      }
    }
    .wizard__body__actions {
      position: static;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: auto;
      padding-right: 0;
      padding-left: 0;
      margin-top: 40px;
    }
    .wizard__body {
      padding-bottom: 0;
    }
    .wizard__body__step {
      padding-bottom: 0;
      background: white;
      border-radius: 4px;
    }
    .wizard-footer {
      position: relative;
      top: 96px;
      width: 100%;
      left: -33px;
    }
    .wizard__next,
    .wizard__back {
      min-width: 188px;
      height: 56px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600 !important;
      float: none !important;
    }
    .wizard__next {
      margin-left: auto;
      position: relative;
      z-index: 10;
    }
    .wizard__back {
      border: 1px solid #006ac3 !important;
      background: none !important;
      color: #006ac3 !important;
    }
  }
  .saveAndLeave {
    position: absolute;
    right: 140px;
    top: 50%;
    transform: translateY(-50%);
  }
  .alert-box {
    .close {
      position: absolute;
      right: 40px;
      z-index: 2;
      padding: 0.75rem 1.25rem;
      top: 50% !important;
      transform: translateY(-50%);
      font-size: 40px;
      font-weight: 200;
      color: #111;
      opacity: 1;
    }
  }

}
</style>

<style scoped lang="scss">
.listing {
  &-easy {
    margin-bottom: 40px;
    min-height: 116px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    @media (max-width: 575px) {
      margin-bottom: 16px;
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      @media (max-width: 991px) {
        margin-bottom: 24px !important;
      }
      @media (max-width: 575px) {
        font-size: 20px;
        text-align: center;
      }
    }
  }
  .select-option-text {
    font-family: "Inter", sans-serif;
    font-size: 16px;
  }
  .vin-lookup {
    padding: 24px;
    background: white;
    border-radius: 4px;
    text-align: center;
    h2 {
      font-size: 34px;
      font-weight: 600;
      color: #006ac3;
      margin-bottom: 24px;
      @media (max-width: 575px) {
        font-size: 28px;
      }
    }
    p {
      width: 660px;
      margin: auto;
      line-height: 24px;
      @media (max-width: 991px) {
        width: 100%;
      }
    }
  }
  .vin-search {
    width: 426px;
    height: 48px;
    border-radius: 35px;
    @media (max-width: 767px) {
      width: 100%;
    }
    button {
      width: 154px;
      font-weight: 500;
      font-size: 16px;
      font-family: "Inter", sans-serif;
      height: 100%;
    }
    input {
      height: 100%;
      box-shadow: none !important;
    }
  }
  .cancel-listing {
    width: 188px;
    height: 56px;
    background: #acacac;
    color: #111;
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .v-icon {
      color: #111;
      margin-right: 8px;
    }
  }
  .vin-details {
    border-radius: 4px;
    background: white;
    margin-top: 40px;
    padding: 32px;
    text-align: center;
    h2 {
      color: #006ac3;
      font-size: 34px;
      margin-bottom: 16px;
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
    .mini-font {
      font-size: 14px;
      color: #363636;
      margin-bottom: 16px;
    }
    .odometr-reading {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
      padding-top: 16px;
    }
    .odometr-box {
      max-width: 426px;
      height: 48px;
      @media (max-width: 575px) {
        max-width: 100%;
      }
      .input-group-append {
        position: relative;
        &:before {
          content: "";
          width: 1px;
          position: absolute;
          left: 0;
          height: 24px;
          background: #d0d0d0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      input,
      div {
        max-height: 100% !important;
        height: 100%;
        border-color: #d0d0d0 !important;
      }
      div {
        border-left: 0 !important;
      }
      input {
        border-right: 0;
        box-shadow: none !important;
      }
    }
    .change-vehicle {
      font-weight: 500;
      padding-bottom: 16px;
      display: inline-block;
    }
    .getstarted-btn {
      width: 426px;
      height: 48px;
      border-radius: 30px;
      margin: auto;
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
#planSelecterbtn {
  min-width: 204px;
  background: #e6f0f9 !important;
  height: 48px;
  border: 1px solid #006ac3 !important;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  font-weight: 500;
  color: #006ac3;
  padding: 0 16px;
}

#planSelector {
  width: 914px;
  margin: auto;
  padding-top: 24px;
  @media (max-width: 991px) {
    width: 100%;
  }
}
.planselector-title {
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  color: #111111;
  margin-bottom: 8px;
}

.planselector-description {
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  color: #111111;
  text-align: center;
}
.planselector-question {
  color: #111111;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 16px;
}
.question-buttons {
  text-align: center;
  margin-bottom: 64px;
  button {
    min-width: 220px;
    border-radius: 30px;
    height: 32px;
    background: none;
    border: 1px solid #006ac3;
    padding: 0 15px;
    color: #006ac3;
    margin: 0 8px;
    svg {
      margin-right: 8px;
    }
  }
}
.vin-input {
  &::placeholder {
    color: #acacac;
    font-size: 14px;
  }
}
</style>
